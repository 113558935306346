import { useMemo } from 'react';
import { footerImages } from '@/constants/footerImages';
import styles from './styles.module.scss';
import { Image as CoreImage } from '@starsoft/common/components';

export default function Responsibilities() {
  const Images = useMemo(
    () =>
      footerImages.map(image => (
        <CoreImage
          className={styles.container__image}
          key={`footer_image_${image}`}
          src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/footer/${image}`}
          alt={image.split('.')[0]}
        />
      )),
    [],
  );

  return <div className={styles.container}>{Images}</div>;
}
