import useTranslation from 'next-translate/useTranslation';
import { FooterNavigationSectionProps } from './props';
import { platformAssets } from '@/utils/assets';
import FooterNavigationSectionPages from './Pages';
import { memo } from 'react';
import styles from './styles.module.scss';

function FooterNavigationSection({ section }: FooterNavigationSectionProps) {
  const { t } = useTranslation('common');

  return (
    <div className={styles.container}>
      <span className={styles.container__title}>
        {t(section.label, {
          provider: platformAssets.app,
          app_provider: platformAssets.app,
        })}
      </span>

      <FooterNavigationSectionPages
        pages={section.pages}
        pageLimit={section.label === 'tab_sportsbook' ? 3 : undefined}
      />
    </div>
  );
}

export default memo(FooterNavigationSection);
