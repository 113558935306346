import { useMemo } from 'react';
import { cryptoCoins } from '@/constants/cryptoCoins';
import styles from './styles.module.scss';
import { Image as CoreImage } from '@starsoft/common/components';

export default function Coins() {
  const CoinsImage = useMemo(
    () =>
      cryptoCoins.map(coin => (
        <CoreImage
          className={styles.container__coin}
          width={40}
          height={40}
          key={`crypto_coin_${coin.id}`}
          src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${coin.image}`}
          alt={coin.name}
        />
      )),
    [],
  );

  return <div className={styles.container}>{CoinsImage}</div>;
}
