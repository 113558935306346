import { Image } from '@starsoft/common/components';
import styled from 'styled-components';
import CoreLink from '@/components/core/Link';

export const Icon = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
`;

export const Link = styled(CoreLink)`
  all: unset;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: opacity 0.25s ease-in-out;
  cursor: pointer;
  position: relative;

  &:hover {
    opacity: 0.8;
  }
`;
