import useTranslation from 'next-translate/useTranslation';
import { Container, Title } from './styles';
import CommunitySectionSocialMedias from './SocialMedias';

export default function CommunitySection() {
  const { t } = useTranslation('common');

  return (
    <Container>
      <Title>{t('join_the_community')}</Title>
      <CommunitySectionSocialMedias />
    </Container>
  );
}
