import { CommunitySectionSocialMediaProps } from '@/components/Footer/Navigation/CommunitySection/SocialMedias/SocialMedia/props';

export const communitySocialMedias: CommunitySectionSocialMediaProps[] = [
  {
    href: '',
    icon: 'telegram.png',
  },
  {
    href: '',
    icon: 'twitter.png',
  },
  {
    href: '',
    icon: 'facebook.png',
  },
  {
    href: '',
    icon: 'discord.png',
  },
  {
    href: '',
    icon: 'instagram.png',
  },
];
