import { useEffect } from 'react';

export function useSiteSealInitializer() {
  function handleInitializeSiteSeal() {
    if (typeof window == 'undefined') {
      return;
    }

    window?.anj_0b147e9d_859a_4c2c_9222_fb80d6fe8b8f?.init?.();
  }

  useEffect(handleInitializeSiteSeal, []);
}
