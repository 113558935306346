import FooterNavigation from './Navigation';
import CoinsSection from './CoinsSection';
import ResponsibilitiesSection from './ResponsibilitiesSection';
import FooterInfo from './Info';
import styles from './styles.module.scss';
import { usePathname } from '@/hooks/usePathname';

function Footer() {
  const pathname = usePathname();
  const hideContainer =
    pathname.includes('/match') ||
    pathname.includes('/bonus') ||
    pathname.includes('/bet-history') ||
    pathname.includes('/sportsbook/category') ||
    pathname.includes('/games/providers') ||
    pathname.includes('/gamblers/category') ||
    pathname.includes('/games/provider') ||
    (pathname.includes('/games/tag') && !pathname.includes('/originals')) ||
    (pathname.includes('/sportsbook/') && !pathname.includes('/sport/'));

  return (
    <footer
      className={`${styles.container} ${hideContainer ? styles['container--hidden'] : ''}`}
    >
      <FooterNavigation />
      <div className={styles.container__divider} />
      <CoinsSection />
      <div className={styles.container__divider} />
      <ResponsibilitiesSection />
      <div className={styles.container__divider} />
      <FooterInfo />
    </footer>
  );
}

export default Footer;
