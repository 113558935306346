import { useMemo } from 'react';
import { pageSections } from '@/constants/sections';
import FooterNavigationSection from './Section';
import CommunitySection from './CommunitySection';
import { promotionSection } from '@/constants/sections/promotion';
import styles from './styles.module.scss';

export default function FooterNavigation() {
  const Sections = useMemo(
    () =>
      pageSections.map((section, i) => (
        <FooterNavigationSection
          key={`footer_navigation_section_item_${i}`}
          section={section}
        />
      )),
    [],
  );

  return (
    <div className={styles.container}>
      {Sections}
      <FooterNavigationSection section={promotionSection} />
      <CommunitySection />
    </div>
  );
}
