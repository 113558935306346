import useTranslation from 'next-translate/useTranslation';
import { platformAssets } from '@/utils/assets';
import { TransWithDefault } from '@starsoft/common/components';
import styles from './styles.module.scss';
import { Image as CoreImage } from '@starsoft/common/components';

export default function FooterInfo() {
  const { t } = useTranslation('common');
  const year = new Date().getFullYear();

  return (
    <div className={styles.container}>
      <div className={styles.container__column}>
        <CoreImage
          className={styles.container__logo}
          width={160}
          height={50}
          src={platformAssets.logo.web.dark}
          alt="logo"
        />

        <div
          className={`${styles.container__column} ${styles['container__column--small']}`}
        >
          <p
            className={`${styles.container__text} ${styles['container__text--left']}`}
          >
            <TransWithDefault
              i18nKey="common:footer_text"
              values={{
                app_provider: platformAssets.app,
              }}
            />
          </p>
        </div>
      </div>

      <div className={styles.container__column}>
        <div className={styles.container__row}>
          <div
            id="anj-0b147e9d-859a-4c2c-9222-fb80d6fe8b8f"
            data-anj-seal-id="0b147e9d-859a-4c2c-9222-fb80d6fe8b8f"
            data-anjimage-size="128"
            data-anj-image-type="basic-small"
            className={styles.container__image}
          />
          <p
            className={`${styles.container__text} ${styles['container__text--left']}`}
          >
            {t('footer_license_description', {
              app_provider: process.env.NEXT_PUBLIC_BET_NAME,
            })}
          </p>
        </div>
        <p
          className={`${styles.container__text} ${styles['container__text--left']} ${styles['container__text--uppercase']}`}
        >
          {t('all_rights', {
            year,
            app_provider: process.env.NEXT_PUBLIC_BET_NAME,
          })}
        </p>
      </div>
    </div>
  );
}
