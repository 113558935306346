import Coins from './Coins';
import BaseSection from '../BaseSection';

export default function CoinsSection() {
  return (
    <BaseSection title="allowed_coins">
      <Coins />
    </BaseSection>
  );
}
