import { useMemo } from 'react';
import { Container } from './styles';
import { communitySocialMedias } from '@/constants/community-social-media';
import CommunitySectionSocialMedia from './SocialMedia';

export default function CommunitySectionSocialMedias() {
  const SocialMedias = useMemo(
    () =>
      communitySocialMedias.map((socialMedia, i) => (
        <CommunitySectionSocialMedia
          key={`community_social_media_${i}`}
          {...socialMedia}
        />
      )),
    [],
  );

  return <Container>{SocialMedias}</Container>;
}
