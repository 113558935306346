import { PropsWithChildren } from 'react';
import { BaseSectionProps } from './props';
import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';

export default function BaseSection({
  title,
  children,
}: PropsWithChildren<BaseSectionProps>) {
  const { t } = useTranslation('common');

  return (
    <div className={styles.container}>
      <h6 className={styles.container__title}>{t(title)}</h6>
      {children}
    </div>
  );
}
