import { memo } from 'react';
import { CommunitySectionSocialMediaProps } from './props';
import { Icon, Link } from './styles';

function CommunitySectionSocialMedia({
  href,
  icon,
}: CommunitySectionSocialMediaProps) {
  return (
    <Link href={href} target="_blank">
      <Icon
        format="square"
        width={65}
        height={65}
        src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/icons/${icon}`}
        alt="social-media"
      />
    </Link>
  );
}

export default memo(CommunitySectionSocialMedia);
