import { Fragment, useMemo } from 'react';
import { FooterNavigationSectionPagesProps } from './props';
import FooterNavigationSectionPage from './Page';
import styles from './styles.module.scss';

export default function FooterNavigationSectionPages({
  pages,
  pageLimit,
}: FooterNavigationSectionPagesProps) {
  const Pages = useMemo(
    () =>
      pages.map((page, i) => {
        if (pageLimit && i > pageLimit - 1) {
          return (
            <Fragment key={`footer_navigation_section_page_${page.label}`} />
          );
        }

        return (
          <FooterNavigationSectionPage
            key={`footer_navigation_section_page_${page.label}`}
            page={page}
          />
        );
      }),
    [pages, pageLimit],
  );

  return <div className={styles.container}>{Pages}</div>;
}
