import { FooterNavigationSectionPageProps } from './props';
import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';
import { platformAssets } from '@/utils/assets';
import Link from '@/components/core/Link';
import styles from './styles.module.scss';
import { useIsPageActive } from '@/hooks/useIsPageActive';

function FooterNavigationSectionPage({
  page,
}: FooterNavigationSectionPageProps) {
  const { t } = useTranslation('common');
  const isActive: boolean = useIsPageActive(page);

  if (page?.hidden) {
    return null;
  }

  return (
    <Link
      className={`${styles.link} ${isActive ? styles['link--active'] : ''}`}
      privateRoute={page.isProtected}
      href={page.paths[0]}
      modal={page.modal}
    >
      {t(page.label, {
        app_provider: platformAssets.app,
      })}
    </Link>
  );
}

export default memo(FooterNavigationSectionPage);
