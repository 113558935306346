import BaseSection from '../BaseSection';
import Responsibilities from './Responsibilities';

export default function ResponsibilitiesSection() {
  return (
    <BaseSection title="sponsorship_and_gaming_responsibilities">
      <Responsibilities />
    </BaseSection>
  );
}
